import * as bootstrap from 'bootstrap';
import slick from 'slick-carousel';

import './auth0.js';
import './locations.js';
import './visits.js';
//import './planner.js';
//=include ../node_modules/@popperjs/core/dist/umd/popper.js
//=include ../node_modules/bootstrap/dist/js/bootstrap.js
//=include ../node_modules/vanilla-lazyload/dist/lazyload.js
//=include ../node_modules/sticky-js/dist/sticky.min.js
//=include ../node_modules/basiclightbox/dist/basicLightbox.min.js
//=include ./components/videoplayer.js

//let Sticky = require('sticky-js');
//let sticky = new Sticky('.header__main-menu');

function isMobile() {
  return window.matchMedia('(max-width:767px)').matches;
}

//initPolyfills();

function initPolyfills() {
  // CSS object-fit for IE
  //objectFitImages();

  // polyfill for IE - startsWith
  if (!String.prototype.startsWith) {
    String.prototype.startsWith = function (searchString, position) {
      position = position || 0;
      return this.indexOf(searchString, position) === position;
    };
  }

  // polyfill for IE - forEach
  if ('NodeList' in window && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
      thisArg = thisArg || window;
      for (var i = 0; i < this.length; i++) {
        callback.call(thisArg, this[i], i, this);
      }
    };
  }
}

// var lazyLoadInstance = new LazyLoad({
//   elements_selector: ".lazy"
// });

window.closeModal = function(){
  jQuery('#wifi').modal('hide');
};

window.parent.closeModal();


jQuery(function () {
  // IE 11 object fit images polyfill
  
  //jQuery('#wifi').modal('close');

  //objectFitImages();

  // Set correct margins to expand the purpose block
  setMarginsForPurposeBlock();

  // Set correct margins to expand the layout block
  setMarginsForLayoutBlock();

  // Override masonary links color from text
  jQuery('.override-link-colors-by-js').each(function() {
    let textColor = jQuery(this).data('color');
    var allAs=jQuery(this).find("a");
    jQuery.each(allAs,function(index,item){
        jQuery(item).css("color", textColor);
    });
    //console.log(textColor);
  });

  // masonry clickable boxes
  jQuery(document).on('click', '.clickable-box', function () {
    window.location.href = jQuery(this).data('click-follow');
  });

})


function setMarginsForPurposeBlock() {

  // Get block width
  let purposeBlockWidth = jQuery('.statement-block').width();

  //Get Page width
  let pageWidth = getWidth();

  // Get the difference between widths
  let difference = pageWidth - purposeBlockWidth;

  // Make difference into prime number
  if (difference % 2 !== 0) {
    difference++;
  }

  //Get margins needed to stretch the block
  let margins = difference / 2;

  //Expand our purpose statement block
  jQuery('.statement-block').css('margin-left',`-${margins}px`);
  jQuery('.statement-block').css('margin-right',`-${margins}px`);

}

function setMarginsForLayoutBlock() {

  // Get block width
  let globalLayoutBlockWidth = jQuery('.global-layout-block').width();

  //Get Page width
  let pageWidth = getWidth();

  // Get the difference between widths
  let difference = pageWidth - globalLayoutBlockWidth;

  // Make difference into prime number
  if (difference % 2 !== 0) {
    difference++;
  }

  //Get margins needed to stretch the block
  let margins = difference / 2;

  //Expand our purpose statement block
  jQuery('.global-layout-block').css('margin-left',`-${margins}px`);
  jQuery('.global-layout-block').css('margin-right',`-${margins}px`);

}

// Get correct page width
function getWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

jQuery(function() {

  setupClickAndCollectUi();
  setupLocationUi();

  jQuery('.topheader__brand-logo').on('mouseover' ,function () {
    jQuery('.topheader__brand-logo').toggleClass('topheader__brand-logo--hovered');
    jQuery('.topheader__brand-logo__svg').toggleClass('topheader__brand-logo__svg--hovered');
  });

  jQuery('.topheader__brand-logo').on('mouseout', function () {
    jQuery('.topheader__brand-logo').toggleClass('topheader__brand-logo--hovered');
    jQuery('.topheader__brand-logo__svg').toggleClass('topheader__brand-logo__svg--hovered');
  });

  // jQuery('.menu-overlay__topmenu__link').on('mouseover' ,function () {
  //   jQuery('.menu-overlay__topmenu__link .closeicon').toggleClass('animate__rotateIn animate__rotateOut');
  // });

  // jQuery('.menu-overlay__topmenu__link').on('mouseout', function () {
  //   jQuery('.menu-overlay__topmenu__link .closeicon').toggleClass('animate__rotateIn animate__rotateOut');
  // });

  function closeMenu() {
    jQuery('#megaMenu').toggleClass('menu-overlay--active');
    jQuery('body').removeClass('overflow-hidden position-fixed');
    jQuery('#menu-overlay-accordion .accordion-template__mainbox__toggleContent').collapse('hide');
    jQuery('#menuToggle').trigger('focus');

  }

  function openMenu() {
    jQuery('#megaMenu').toggleClass('menu-overlay--active');
    jQuery('body').addClass('overflow-hidden position-fixed');
    
    jQuery('.topheader__brand-logo').trigger('focus');
    // jQuery('.menu-overlay__topmenu__link').trigger('focus'); // dunno why tf it won't work on this one

    
  }
  
  jQuery('.menu-overlay__topmenu__link').on('click', function (e) {
    e.preventDefault();
    if (jQuery('#megaMenu').hasClass('menu-overlay--active')) {
      closeMenu();
    }
  });
  
  jQuery('#menuToggle').on('click', function (e) {
    e.preventDefault();
    openMenu();
  })

  // focus menu

  let tabBack = false
  
  jQuery(this).on('keydown', function(e){
    if (e.shiftKey && e.key === 'Tab') { // shift+tab pressed
      tabBack = true;
    } else { //only tab pressed
      tabBack = false;
    }
  })

  jQuery('#menuToggle').on('focusout', function(e) {    
    if (jQuery('body').hasClass('home') && tabBack === false) jQuery('#near-me-tab').trigger('focus'); 
  })

  jQuery('.menu-overlay a.about-us').on('focusout', function() {
    jQuery('a.menu-overlay__topmenu__link').trigger('focus');
  })

  jQuery('#near-me-tab').on('focusout', function() {
    if(tabBack === true) jQuery('#menuToggle').trigger('focus');
  })

  jQuery('.topheader__menus__menu-link.services').on('click', function(e) {
    e.preventDefault();
    if (!jQuery('.topheader #services').hasClass('active')) {
      jQuery('.topheader #search').removeClass('animate__fadeInDown active').addClass('animate__fadeOutUp');
      jQuery('.topheader #services').addClass('animate__fadeInDown active').removeClass('animate__fadeOutUp');
    } else {
      jQuery('.topheader #services').removeClass('animate__fadeInDown active').addClass('animate__fadeOutUp');
    }
  })

  jQuery('.topheader__menus__menu-link.search').on('click', function(e) {
    e.preventDefault();
    if (!jQuery('.topheader #search').hasClass('active')) {
      jQuery('.topheader #services').removeClass('animate__fadeInDown active').addClass('animate__fadeOutUp');
      jQuery('.topheader #search').addClass('animate__fadeInDown active').removeClass('animate__fadeOutUp');
    } else {
      jQuery('.topheader #search').removeClass('animate__fadeInDown active').addClass('animate__fadeOutUp');
    }
  });



   // overlay menu toggle function js

   jQuery(".menu-overlay__menu-content__menu-box__main-menu").contents().find("a").on('click', function () {

    jQuery('.menu-overlay__menu-content__menu-box__sub-menu').removeClass('show');
    jQuery('.menu-overlay__menu-content__menu-box__main-menu').children('li').children('a').each(function (i) {
      jQuery(this).removeClass('active');
    });

    if (this.className) {
      jQuery(`.menu-overlay__menu-content__menu-box #${this.className}`).addClass('show');
      jQuery(this).addClass('active');
      return true;
    }
    // return false;
  });




  /**
   * near you page filter toggle functuon
   */
  jQuery('#filterBtn').on('click', function () {
    jQuery(this).toggleClass('hamburger--spin is-active');
    jQuery('#filterContent').toggleClass('nearu-page__filterContent--show');

    if (isMobile() === false) {
      jQuery('.breadcrumb > .inner').toggleClass('invisible');
    }

    if (jQuery(this).hasClass('is-active') === true) {
      jQuery('#filterBtn > .text').text('Close filter options');
    } else {
      jQuery('#filterBtn > .text').text('Filter options');
      return false;
    };

  });

  jQuery('#searchinput').on('keypress', function(event){
    let keycode = (event.keyCode ? event.keyCode : event.which);
    if(keycode == '13'){
      event.preventDefault();
      let term = jQuery('#searchinput').val();
      if (term != '') {
        let uri = `/?s=${term}`
        window.location.href = encodeURI(uri);
      }
    }
  });

  jQuery('#careers-hamburger').on('click', function () {
    jQuery(this).toggleClass('hamburger--collapse is-active');
  });

  jQuery('.site-search-btn').on('click', function () {
    let term = jQuery('#searchinput').val();
    if (term != '') {
      let uri = `/?s=${term}`
      window.location.href = encodeURI(uri);
    }
  });

  /**
   * Customer reviews slider
   */
  jQuery('#customerReviews').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    swipeToSlide: true,
    centerMode: true,
    centerPadding: '8px',
    responsive: [{
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  jQuery('#brandSlider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    swipeToSlide: true,
    centerMode: true,
    centerPadding: '8px',
    responsive: [{
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  jQuery('.brand_slider').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    swipeToSlide: true,
    centerMode: true,
    centerPadding: '0',
  });
  
  jQuery('.offer_slider').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    swipeToSlide: true,
    centerMode: true,
    centerPadding: '0',
  });

  /**
   * Customer reviews slider
   */
  jQuery('#facilities-home').slick({
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    swipeToSlide: true,
    centerMode: true,
    centerPadding: '8px',
    responsive: [{
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  /**
   * masonry slider for mobile
   */
  jQuery('#masonry-slider').slick({
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    swipeToSlide: true,
    centerMode: true,
    centerPadding: '16px',
    responsive: [{
      breakpoint: 500,
      settings: {
        adaptiveHeight: true
      }
    }
  ]
  });

  // setting min height for sliders
  var getFullHeight = jQuery('#facilities-home').innerHeight();
  if(!isMobile() && getFullHeight){
    jQuery('#facilities-home .slick-list .slick-track').children().children().children().css('min-height', getFullHeight);
  }
  var customerReviews = jQuery('#customerReviews').innerHeight();
  if(!isMobile() && customerReviews){
    jQuery('#customerReviews .slick-list .slick-track').children().children().children().css('min-height', customerReviews);
  }

  var brandSlider = jQuery('#brandSlider').innerHeight();
  if(!isMobile() && brandSlider){
    jQuery('#brandSlider .slick-list .slick-track').children().children().children().css('min-height', brandSlider);
  }

  jQuery('#list-style').on('click', function() {
    jQuery('#apply-style').removeClass('box-style');
  })

  jQuery('#box-style').on('click', function() {
    jQuery('#apply-style').addClass('box-style');
  })


  jQuery(".search-widget #near-me .input-div input").each(
    function () {
      jQuery(this).data('holder', jQuery(this).attr('placeholder'));
      jQuery(this).on('focusin', function () {
        jQuery(this).attr('placeholder', '');
        jQuery('.search-widget .input-div > .search-button').hide();
      });
      jQuery(this).on('focusout', (function () {
        jQuery(this).attr('placeholder', jQuery(this).data('holder'));
        jQuery('.search-widget .input-div > .search-button').show();
      }));

    });


  const purposeBlock = document.querySelectorAll('.our-purpose-block');
  //console.log('purposeBlock', purposeBlock)

  const config = {
    rootMargin: '0px',
    threshold: 0.9
  };

  const io = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('hovered');
      } else {
        entry.target.classList.remove('hovered');
      }
    })
  }, config)

  purposeBlock.forEach((el) => {
    io.observe(el);
  })

  /**
   * Handle a user clicking on a click and collect link
   */
  function setupClickAndCollectUi() {
    jQuery(document).on('click', '.locationBrands__body__inner__brand-box.locationBrands_cc', function (e) {
      e.stopPropagation();
      // get the preoday URL
      var clickAndCollectUrl = e.currentTarget.dataset.clickAndCollectUrl;
      var form = document.getElementById('click-and-collect-form');
      form.setAttribute('action', clickAndCollectUrl);
      form.submit();
    })
  }

  /**
   * The get location interface
   */
  
  function setupLocationUi() {

    jQuery("#location").on('keydown', function (e) {
      console.log(e.key);
      if (e.key === 'Enter') {
        e.preventDefault();
        jQuery("#locationButton").trigger('click');
      }
    });

    jQuery('#locationButton').on('click', function (e) {
      e.preventDefault();
      if (navigator.geolocation) {
        jQuery('#locationButton').addClass('location-button__waiting')
        jQuery('#location').val('Finding your position...')
        navigator.geolocation.getCurrentPosition(function (position) {
          let lat = position.coords.latitude.toFixed(4);
          let lng = position.coords.longitude.toFixed(4);
          console.log(lat, lng);
          // Now we have the users position, save it to the API...
          jQuery('#locationButton').removeClass('location-button__waiting')
          jQuery('#location').val(lat + ', ' + lng)
          window.location.href = `/services?lat=${lat}&lng=${lng}`
        });
      } else {
        alert("Please allow location lookup to use this feature.");
      }
    });
  };

  jQuery(".search.searchHero").on('keydown', function (e) {
    console.log(e.key);
    if (e.key === 'Enter') {
      e.preventDefault();
      jQuery("#vajobs").trigger('click');
    }
  });

  jQuery("#vajobs").on("click", function (el) {
    if (navigator.geolocation) {
      jQuery("#searchButton").addClass("location-button__waiting");
      jQuery(".searchHero").val("Finding your position...");
      navigator.geolocation.getCurrentPosition(function (position) {
        let lat = position.coords.latitude.toFixed(4);
        let lng = position.coords.longitude.toFixed(4);
        // Now we have the users position, save it to the API...
        jQuery("#searchButton").removeClass("location-button__waiting");
        jQuery(".searchHero").val(lat + ", " + lng);
        window.location.href = `/services?lat=${lat}&lng=${lng}&ugeo=1`;
      });
    } else {
      alert("Please allow location lookup to use this feature.");
    }
  });

  jQuery("#vacanciesnu").on("click", function (e) {
    e.preventDefault();

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {

        let lat = position.coords.latitude.toFixed(4);
        let lng = position.coords.longitude.toFixed(4);

        window.location.href = `/about-us/careers/vacancies-near-you/?lat=${lat}&lng=${lng}&ugeo=1`;

      });
    } else {
      alert("Please allow location lookup to use this feature.");
    }

  });

  jQuery(".main-footer__menus__menublock .menu-item-5261").on("click", function (e) {
    e.preventDefault();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let lat = position.coords.latitude.toFixed(4);
        let lng = position.coords.longitude.toFixed(4);
        window.location.href = `/services?lat=${lat}&lng=${lng}`;
      });
    } else {
      window.location.href = `/services/`;
    }

  });

});


